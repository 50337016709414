import { type DB_Record } from ".";

export interface DB_StoreProductDocument extends DB_Record, DB_StoreProductDocumentFields {}

export enum TimeUnit {
  UNKNOWN,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

export interface DB_StoreProductDocumentFields {
  title: string;
  description: string;
  image: string | null;
  subItems: string[];
  price: string;
  visibility: boolean;
  commands: ProductCommandConfig[];
  limits: StoreProductLimitsConfig;
  giftingConfig: StoreProductGiftingConfig;
  upSellConfig: ProductSuggestions;
}

export interface StoreProductLimitsConfig {
  global: StoreProductLimit | null;
  user: StoreProductLimit | null;
}

export interface StoreProductLimit {
  maxCount: string;
  limitTimeUnitsCount: string;
  limitTimeUnit: string;
}

export interface StoreProductGiftingConfig {
  enabled: boolean; // false by default
}

export interface ProductSuggestions {
  alternativeProducts: string[] | null;
  additionalProduct: AdditionalProductConfig | null;
}

export interface AdditionalProductConfig extends DB_Record {
  discount: number; // from 0 - 100
}

export interface PortalCreateStoreProductBody {
  storeId: string;
  categoryIds: string[];
  product: DB_StoreProductDocumentFields;
}

export interface PortalRemoveStoreProductBody {
  storeId: string;
  productId: string;
}

export interface PortalUpdateStoreProductBody {
  storeId: string;
  categoryIds: string[];
  product: DB_StoreProductDocument;
}

export interface ProductCommandConfig {
  command: string;
  userOnlineRequired: boolean;
}
