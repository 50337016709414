/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from "react";
import { useAppSelector } from "store/hooks";
import { viewsSelector } from "store/slices/views";
import { type IOpenedModal, ModalName } from "store/types/views";
import StoreCategoryModal, { type StoreCategoryModalProps } from "../Store/StoreCategoryModal";
import CreateProjectModal, { type CreateProjectModalProps } from "../Project/CreateProjectModal";
import StoreModal, { type ModalProps } from "../Store/StoreModal";
import ProfileDetailsModal, { type ProfileDetailsModalProps } from "../User/ProfileDetailsModal";
import SignInModal, { type SignInModalProps } from "../User/SignInModal";
import SignOutModal, { type SignOutModalProps } from "../User/SignOutModal";
import SignUpModal, { type SignUpModalProps } from "../User/SignUpModal";
import ConfirmationModal, { type ConfirmationModalProps } from "../shared/ConfirmationModal";
import StoreProductModal, { type StoreProductModalProps } from "../Store/StoreProductModal";
import CloneStoreProductModal, { type CloneStoreProductModalProps } from "../Store/CloneStoreProductModal";
import SelectProductModal, { type SelectProductModalProps } from "../Store/SelectProductModal";
import ShowDnsRecordsModal, { type ShowDnsRecordsModalProps } from "../Store/ShowDnsRecordsModal";

const getSignInModal = (modal: IOpenedModal<SignInModalProps>) => <SignInModal key={modal.name} {...modal.props} />;
const getSignUpModal = (modal: IOpenedModal<SignUpModalProps>) => <SignUpModal key={modal.name} {...modal.props} />;
const getSignOutModal = (modal: IOpenedModal<SignOutModalProps>) => <SignOutModal key={modal.name} {...modal.props} />;
const getProjectDetails = (modal: IOpenedModal<ProfileDetailsModalProps>) => (
  <ProfileDetailsModal key={modal.name} {...modal.props} />
);
const getCreateProjectModal = (modal: IOpenedModal<CreateProjectModalProps>) => (
  <CreateProjectModal key={modal.name} {...modal.props} />
);
const getCreateStoreModal = (modal: IOpenedModal<ModalProps>) => <StoreModal key={modal.name} {...modal.props} />;
const getCreateCategoryModal = (modal: IOpenedModal<StoreCategoryModalProps>) => (
  <StoreCategoryModal key={modal.name} {...modal.props} />
);
const getConfirmationModal = (modal: IOpenedModal<ConfirmationModalProps>) => (
  <ConfirmationModal key={modal.name} {...modal.props} />
);
const getCreateStoreProductModal = (modal: IOpenedModal<StoreProductModalProps>) => (
  <StoreProductModal key={modal.name} {...modal.props} />
);
const getCloneStoreProductModal = (modal: IOpenedModal<CloneStoreProductModalProps>) => (
  <CloneStoreProductModal key={modal.name} {...modal.props} />
);
const getSelectProductModal = (modal: IOpenedModal<SelectProductModalProps>) => (
  <SelectProductModal key={modal.name} {...modal.props} />
);
const getShowDnsRecordsModal = (modal: IOpenedModal<ShowDnsRecordsModalProps>) => (
  <ShowDnsRecordsModal key={modal.name} {...modal.props} />
);

export const ModalsController = () => {
  const modals = useAppSelector(viewsSelector.modals);

  return (
    <>
      {modals.map((modal) => {
        switch (modal.name) {
          case ModalName.SignInModal:
            return getSignInModal(modal);
          case ModalName.SignUpModal:
            return getSignUpModal(modal);
          case ModalName.SignOutModal:
            return getSignOutModal(modal);
          case ModalName.ProfileDetails:
            return getProjectDetails(modal);
          case ModalName.CreateProjectModal:
            return getCreateProjectModal(modal);
          case ModalName.StoreModal:
            return getCreateStoreModal(modal);
          case ModalName.StoreCategoryModal:
            return getCreateCategoryModal(modal);
          case ModalName.ConfirmationModal:
            return getConfirmationModal(modal);
          case ModalName.StoreProductModal:
            return getCreateStoreProductModal(modal);
          case ModalName.CloneStoreProductModal:
            return getCloneStoreProductModal(modal);
          case ModalName.SelectProductModal:
            return getSelectProductModal(modal);
          case ModalName.ShowDnsRecordsModal:
            return getShowDnsRecordsModal(modal);
          default:
            return null;
        }
      })}
    </>
  );
};
