import { DialogContent, Grid } from "@mui/material";

import CategoryTitleField from "./components/fields/CategoryTitleField";
import CategoryProductsField from "./components/fields/CategoryProductsField";
import CategoryEnabledField from "./components/fields/CategoryEnabledField";
import CategoryVisibilitySection from "./components/sections/CategoryVisibilitySection";
import CategoryVisibleField from "./components/fields/CategoryVisibleField";
import { useContext } from "react";
import StoreCategoryContext from "./context/StoreCategoryContext";

const FormBody = () => {
  const { visibilityConfig } = useContext(StoreCategoryContext);
  return (
    <DialogContent dividers>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <CategoryTitleField />
        </Grid>
        <Grid item xs={12}>
          <CategoryProductsField />
        </Grid>
        <Grid item xs={12}>
          <CategoryEnabledField />
        </Grid>
        {!visibilityConfig ? (
          <Grid item xs={12}>
            <CategoryVisibleField />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <CategoryVisibilitySection />
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default FormBody;
