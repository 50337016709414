import useProject from "pages/Dashboard/hooks/useProject";
import { useCallback, useContext } from "react";
import StoreModalContext from "../../../context/StoreModalContext";
import { dispatch } from "store/hooks";
import { storeMiddleware } from "store/slices/store";
import { type PortalCreateStoreBody } from "dataLayer/types/storeEndpoints";

const useStoreModalHooks = () => {
  const { PROJECT } = useProject();
  const context = useContext(StoreModalContext);

  const createStore = useCallback(() => {
    if (!PROJECT) {
      return;
    }
    const body: PortalCreateStoreBody = {
      title: context.storeTitle!,
      customDomain: context.url,
      storeType: context.storeType!,
      subdomain: context.subdomain!,
      icon: context.icon ?? null,
      projectId: PROJECT.id,
    };
    dispatch(storeMiddleware.createStore(body));
  }, [PROJECT, context]);

  return { onSubmit: createStore };
};
export default useStoreModalHooks;
