import { Card, CardActionArea, CardMedia, styled } from "@mui/material";
import { type MouseEvent, useContext } from "react";
import StoreModalContext from "../../context/StoreModalContext";
import { type IClientStoreType } from "dataLayer/types/storeTypeEndpoints";
import useStoreTypes from "pages/Dashboard/hooks/useStoreTypes";

export interface StoreTypeCardProps {
  config: IClientStoreType;
}

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.1s ease-in-out",
  "&:hover": { transform: "scale3d(1.04, 1.04, 1.04)" },
}));

const StoreTypeCard = ({ config }: StoreTypeCardProps) => {
  const { getStoreTypeById } = useStoreTypes();
  const title = getStoreTypeById(config.id)?.title;
  const { step, setStep, setStoreType } = useContext(StoreModalContext);

  const onClick = (_: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    setStoreType(config.id);
    setStep(step + 1);
  };

  return (
    <StyledCard>
      <CardActionArea onClick={onClick}>
        <CardMedia component="img" image={config.backgroundImageURL ?? ""} alt={title} />
      </CardActionArea>
    </StyledCard>
  );
};

export default StoreTypeCard;
