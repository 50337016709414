import { type AppDispatch } from "store/store";
import slice from "./slice";
import FirebaseManager from "dataLayer/FirebaseManager";
import { viewsMiddleware } from "../views";
import { ModalName, SeveritiesType } from "store/types/views";
import {
  type PortalRemoveStoreBody,
  type PortalCreateStoreBody,
  type PortalGetStoreBody,
  type PortalCheckSubdomainBody,
  type PortalUpdateStoreBody,
} from "dataLayer/types/storeEndpoints";
import { Translations } from "constants/translations";
import { dashboardMiddleware } from "../dashboard";
import {
  type PortalRemoveStoreCategoryBody,
  type PortalCreateStoreCategoryBody,
  type PortalUpdateStoreCategoryBody,
} from "dataLayer/types/storeCategoryEndpoints";
import {
  type PortalUpdateStoreProductBody,
  type PortalCreateStoreProductBody,
  type PortalRemoveStoreProductBody,
} from "dataLayer/types/storeProductEndpoints";
import { getSubdomainCheckInitialState } from "./initialState";

const {
  setStoreLoading,
  setStoreCreateLoading,
  setStoreUpdateLoading,
  setCurrentStoreData,
  setCurrentStoreLoading,
  setStoreCategoryLoading,
  setStoreProductLoading,
  setStoreTypes,
  setStoreTypesLoading,
  setSubdomainCheckResult,
  setSubdomainCheckResultLoading,
} = slice.actions;

const getStoreTypes = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreTypesLoading(true));
    const response = await FirebaseManager.API.Portal.getPortalStoreTypes({});
    dispatch(setStoreTypes(response.data.storeTypes));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_TYPES_GET,
        },
      }),
    );
  } finally {
    dispatch(setStoreTypesLoading(false));
  }
};

const createStore = (data: PortalCreateStoreBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreCreateLoading(true));
    const response = await FirebaseManager.API.Portal.createStore(data);
    dispatch(dashboardMiddleware.updateUserSingleProject(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_CREATE,
        },
      }),
    );
  } finally {
    dispatch(setStoreCreateLoading(false));
  }
};
const updateStore = (data: PortalUpdateStoreBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreUpdateLoading(true));
    const response = await FirebaseManager.API.Portal.updateStore(data);
    dispatch(dashboardMiddleware.updateUserSingleProject(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_CREATE,
        },
      }),
    );
  } finally {
    dispatch(setStoreUpdateLoading(false));
  }
};
const removeStore = (data: PortalRemoveStoreBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreLoading(data.storeId));
    const response = await FirebaseManager.API.Portal.removeStore(data);
    dispatch(dashboardMiddleware.updateUserSingleProject(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmationModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_REMOVE,
        },
      }),
    );
  } finally {
    dispatch(setStoreLoading(null));
  }
};

const getStoreDocument = (data: PortalGetStoreBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setCurrentStoreLoading(true));
    const response = await FirebaseManager.API.Portal.getStore(data);
    dispatch(setCurrentStoreData(response.data));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_GET,
        },
      }),
    );
  } finally {
    dispatch(setCurrentStoreLoading(false));
  }
};

const createStoreCategory = (data: PortalCreateStoreCategoryBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreCategoryLoading(true));
    const response = await FirebaseManager.API.Portal.createStoreCategory(data);
    dispatch(setCurrentStoreData(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreCategoryModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_CATEGORY_CREATE,
        },
      }),
    );
  } finally {
    dispatch(setStoreCategoryLoading(false));
  }
};

const updateStoreCategory = (data: PortalUpdateStoreCategoryBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreCategoryLoading(true));
    const response = await FirebaseManager.API.Portal.updateStoreCategory(data);
    dispatch(setCurrentStoreData(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreCategoryModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_CATEGORY_CREATE,
        },
      }),
    );
  } finally {
    dispatch(setStoreCategoryLoading(false));
  }
};

const removeStoreCategory = (data: PortalRemoveStoreCategoryBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreCategoryLoading(true));
    const response = await FirebaseManager.API.Portal.removeStoreCategory(data);
    dispatch(setCurrentStoreData(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmationModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_CATEGORY_REMOVE,
        },
      }),
    );
  } finally {
    dispatch(setStoreCategoryLoading(false));
  }
};

const createStoreProduct = (data: PortalCreateStoreProductBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreProductLoading(true));
    const response = await FirebaseManager.API.Portal.createStoreProduct(data);
    dispatch(setCurrentStoreData(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreProductModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_PRODUCT_CREATE,
        },
      }),
    );
  } finally {
    dispatch(setStoreProductLoading(false));
  }
};

const updateStoreProduct = (data: PortalUpdateStoreProductBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreProductLoading(true));
    const response = await FirebaseManager.API.Portal.updateStoreProduct(data);
    dispatch(setCurrentStoreData(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreProductModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_PRODUCT_UPDATE,
        },
      }),
    );
  } finally {
    dispatch(setStoreProductLoading(false));
  }
};

const removeStoreProduct = (data: PortalRemoveStoreProductBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setStoreProductLoading(true));
    const response = await FirebaseManager.API.Portal.removeStoreProduct(data);
    dispatch(setCurrentStoreData(response.data));
    dispatch(viewsMiddleware.closeModal(ModalName.ConfirmationModal));
    dispatch(viewsMiddleware.closeModal(ModalName.StoreProductModal));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_PRODUCT_REMOVE,
        },
      }),
    );
  } finally {
    dispatch(setStoreProductLoading(false));
  }
};

const resetSubdomainCheck = () => (dispatch: AppDispatch) => {
  const initialState = getSubdomainCheckInitialState();
  dispatch(setSubdomainCheckResult(initialState.result));
  dispatch(setSubdomainCheckResultLoading(initialState.isLoading));
};

const checkSubdomain = (data: PortalCheckSubdomainBody) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setSubdomainCheckResultLoading(true));
    const response = await FirebaseManager.API.Portal.checkSubdomain(data);
    dispatch(setSubdomainCheckResult(response.data.isAvailable));
  } catch (error) {
    dispatch(
      viewsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          message: Translations.TOAST_ERROR_STORE_SUBDOMAIN_CHECK,
        },
      }),
    );
  } finally {
    dispatch(setSubdomainCheckResultLoading(false));
  }
};

export default {
  getStoreTypes,
  createStore,
  updateStore,
  removeStore,
  getStoreDocument,
  createStoreCategory,
  updateStoreCategory,
  removeStoreCategory,
  createStoreProduct,
  updateStoreProduct,
  removeStoreProduct,
  checkSubdomain,
  resetSubdomainCheck,
};
