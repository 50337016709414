import {
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  IconButton,
  CircularProgress,
  Skeleton,
  Button,
} from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { type IStoreMeta } from "store/types/dashboard";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useCallback, useMemo } from "react";
import { dispatch, useAppSelector } from "store/hooks";
import useProject from "pages/Dashboard/hooks/useProject";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { storeMiddleware, storeSelector } from "store/slices/store";
import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import useStoreTypes from "pages/Dashboard/hooks/useStoreTypes";

export interface ProjectStoreCardViewProps {
  storeData: IStoreMeta;
}

const ProjectStoreCardView = ({ storeData }: ProjectStoreCardViewProps) => {
  const [t] = useTranslation();
  const { PROJECT } = useProject();
  const navigate = useNavigate();
  const { getStoreTypeById } = useStoreTypes();
  const projectStoreInLoading = useAppSelector(storeSelector.projectStoreInLoading);
  const storeTypesLoading = useAppSelector(storeSelector.storeTypesLoading);

  const storeTypeDetails = getStoreTypeById(storeData.storeType);

  const isDisabled = useMemo(() => !storeTypeDetails, [storeTypeDetails]);

  const isStoreLoading = useMemo(
    () => storeTypesLoading || projectStoreInLoading === storeData.id,
    [storeTypesLoading, storeData, projectStoreInLoading],
  );

  const storePath = useMemo(
    () => (storeTypeDetails ? `https://${storeData.subdomain}.${storeTypeDetails.key}.survival-portal.ru` : "#"),
    [storeData.subdomain, storeTypeDetails],
  );

  const dashboardPath = useMemo(
    () => `/dashboard/${PROJECT!.id}/${storeData.storeType}/${storeData.id}`,
    [storeData.id, storeData.storeType, PROJECT],
  );

  const onRemoveCLick = useCallback(() => {
    if (!PROJECT || !storeData) {
      return;
    }
    const props: ConfirmationModalProps = {
      title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_STORE),
      subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_STORE, {
        storeTitle: storeData.title,
      }),
      confirmationCallback: () => {
        dispatch(
          storeMiddleware.removeStore({
            storeId: storeData.id,
            projectId: PROJECT.id,
          }),
        );
      },
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmationModal,
        props,
      }),
    );
  }, [storeData, PROJECT, t]);

  const onViewClick = useCallback(() => {
    window.open(storePath, "_blank");
  }, [storePath]);

  const onDashboardClick = useCallback(() => {
    navigate(dashboardPath);
  }, [dashboardPath, navigate]);

  return (
    <Card sx={{ maxWidth: 345, height: 180 }} variant="outlined">
      {isStoreLoading ? (
        <CardContent>
          <Grid item container justifyContent="space-between">
            <Typography gutterBottom variant="h5" component="div">
              {storeData.title}
            </Typography>
            <CircularProgress size={24} />
          </Grid>
          <Grid item>
            <Skeleton variant="rounded" width={210} height={95} />
          </Grid>
        </CardContent>
      ) : (
        <Grid item alignItems={"flex-end"}>
          <CardContent>
            <Grid item container justifyContent="space-between">
              <Typography gutterBottom variant="h6" component="div">
                {storeData.title}
              </Typography>
              <Grid item>
                {storeData.subdomain ? (
                  <IconButton
                    color="primary"
                    title={t(Translations.PAGE_PROJECT_STORE_BUTTON_REMOVE)}
                    onClick={onViewClick}
                    disabled={isDisabled}
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : null}

                <IconButton
                  color="error"
                  title={t(Translations.PAGE_PROJECT_STORE_BUTTON_REMOVE)}
                  onClick={onRemoveCLick}
                  disabled={isDisabled}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <Grid item sx={{ p: "5px" }}>
              <Typography variant="body2" color="text.secondary">
                {storeTypeDetails?.title ?? t(Translations.PAGE_PROJECT_STORE_TYPE_NOT_FOUND)}
              </Typography>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="text" onClick={onDashboardClick} disabled={isDisabled}>
              {t(Translations.PAGE_PROJECT_STORE_BUTTON_ENTER)}
            </Button>
          </CardActions>
        </Grid>
      )}
    </Card>
  );
};

export default ProjectStoreCardView;
