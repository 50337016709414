import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import StoreCategoryContext from "../../context/StoreCategoryContext";

const CategoryTitleField = () => {
  const [t] = useTranslation();
  const { title, setTitle } = useContext(StoreCategoryContext);

  return (
    <TextField
      autoFocus
      required
      margin="dense"
      key={Translations.MODAL_CREATE_CATEGORY_FIELD_NAME}
      name={Translations.MODAL_CREATE_CATEGORY_FIELD_NAME}
      label={t(Translations.MODAL_CREATE_CATEGORY_FIELD_NAME)}
      type="text"
      fullWidth
      variant="outlined"
      value={title}
      onChange={(event) => {
        setTitle(event.target.value);
      }}
    />
  );
};

export default CategoryTitleField;
