import React, { useContext } from "react";
import { DialogTitle, Divider, IconButton } from "@mui/material";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import StoreModalContext from "./context/StoreModalContext";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

interface FormHeaderProps {
  onClose: (event: React.SyntheticEvent, reason?: string) => void;
}
const FormHeader = ({ onClose }: FormHeaderProps) => {
  const [t] = useTranslation();
  const { storeId } = useContext(StoreModalContext);
  const isStoreCreateLoading = useAppSelector(storeSelector.isStoreCreateLoading);

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t(storeId ? Translations.MODAL_EDIT_STORE_TITLE : Translations.MODAL_CREATE_STORE_TITLE)}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        disabled={isStoreCreateLoading}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
    </>
  );
};

export default FormHeader;
