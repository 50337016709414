import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import { type ShowDnsRecordsModalProps } from "components/modals/Store/ShowDnsRecordsModal";
import { Translations } from "constants/translations";
import { type PortalUpdateStoreBody } from "dataLayer/types/storeEndpoints";
import useProject from "pages/Dashboard/hooks/useProject";
import React, { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";
import { dispatch, useAppSelector } from "store/hooks";
import { storeMiddleware, storeSelector } from "store/slices/store";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import SubDomainField from "./fields/SubDomainField";
import StoreSettingsContext from "../context/StoreSettingsContext";

const subdomainRegex = /^(?!-)[a-z0-9-]{1,63}(?<!-)$/i;
const domainRegex = /^(?!-)[a-z0-9-]{1,63}(?<!-)$/i;
const zoneRegex = /^[a-z]{2,63}$/i;

const validateUrl = (url?: string | null): boolean => {
  if (!url) return false;

  const parts = url.split(".");
  if (parts.length < 2) return false;

  const zone = parts.pop();
  const domain = parts.pop();
  const subdomains = parts;

  if (!zone || !zoneRegex.test(zone)) return false;
  if (!domain || !domainRegex.test(domain)) return false;

  for (const subdomain of subdomains) {
    if (!subdomainRegex.test(subdomain)) return false;
  }

  return true;
};

const StoreSettingsContent = () => {
  const [t] = useTranslation();
  const context = useContext(StoreSettingsContext);
  const { PROJECT } = useProject();
  const { storeTitle, setStoreTitle, icon, setIcon, url, setUrl } = context;
  const currentStore = useAppSelector(storeSelector.store);
  const isStoreRemoveLoading = useAppSelector(storeSelector.isStoreRemoveLoading);
  const isStoreUpdateLoading = useAppSelector(storeSelector.isStoreUpdateLoading);
  const isLoading = isStoreRemoveLoading || isStoreUpdateLoading;

  const oldState: PortalUpdateStoreBody = useMemo(() => {
    return {
      projectId: PROJECT!.id,
      id: currentStore!.id,
      title: currentStore!.title ?? null,
      icon: currentStore!.icon ?? null,
      settings: {
        ...currentStore!.settings,
        customDomain: currentStore!.settings.customDomain ?? null,
        subdomain: currentStore!.settings.subdomain,
      },
    };
  }, [currentStore, PROJECT]);

  const newState: PortalUpdateStoreBody = useMemo(
    () => ({
      projectId: PROJECT!.id,
      id: currentStore!.id,
      title: context.storeTitle!,
      icon: context.icon ?? null,
      settings: {
        ...currentStore!.settings,
        subdomain: context.subdomain!,
        customDomain: context.url,
      },
    }),
    [PROJECT, context, currentStore],
  );

  const settingsChanged = useMemo(() => {
    return JSON.stringify(oldState) !== JSON.stringify(newState);
  }, [oldState, newState]);

  const updateStore = useCallback(() => {
    dispatch(storeMiddleware.updateStore(newState));
  }, [newState]);

  const onSubmitClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (!settingsChanged) {
        return;
      }
      updateStore();
    },
    [updateStore, settingsChanged],
  );

  const openShowDnsRecordsModal = useCallback(() => {
    if (!url) {
      return;
    }
    const props: ShowDnsRecordsModalProps = {
      url,
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ShowDnsRecordsModal,
        props,
      }),
    );
  }, [url]);

  const onRemoveCLick = useCallback(() => {
    if (!PROJECT || !currentStore) {
      return;
    }
    const props: ConfirmationModalProps = {
      title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_STORE),
      subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_STORE, {
        storeTitle: currentStore.title,
      }),
      confirmationCallback: () => {
        dispatch(
          storeMiddleware.removeStore({
            storeId: currentStore.id,
            projectId: PROJECT.id,
          }),
        );
      },
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.ConfirmationModal,
        props,
      }),
    );
  }, [currentStore, PROJECT, t]);

  return (
    <Form onSubmit={onSubmitClick}>
      <Grid container sx={{ padding: "10px" }}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            required
            margin="dense"
            key={Translations.MODAL_CREATE_STORE_FORM_FIELD_TITLE}
            name="storeTitle"
            label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_TITLE)}
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={storeTitle}
            onChange={(_) => {
              setStoreTitle(_.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SubDomainField />
        </Grid>
        <Grid container item xs={12} justifyContent={"space-between"} spacing={1}>
          <Grid item xs={10}>
            <TextField
              margin="dense"
              key={Translations.MODAL_CREATE_STORE_FORM_FIELD_CUSTOM_DOMAIN}
              name="customURL"
              label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_CUSTOM_DOMAIN)}
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={url}
              onChange={(_) => {
                setUrl(_.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2} alignContent={"center"}>
            <LoadingButton color={"success"} disabled={!validateUrl(url)} onClick={openShowDnsRecordsModal}>
              {t(Translations.PAGE_PROJECT_STORE_SETTINGS_BUTTON_DNS)}
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            key={Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON}
            name="icon"
            label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON)}
            type="text"
            fullWidth
            variant="outlined"
            defaultValue={icon}
            onChange={(_) => {
              setIcon(_.target.value);
            }}
          />
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Grid item sx={{ mt: "10px" }}>
            <LoadingButton disabled={isLoading} loading={isLoading} color={"error"} onClick={onRemoveCLick}>
              {t(Translations.PAGE_PROJECT_STORE_SETTINGS_BUTTON_REMOVE)}
            </LoadingButton>
          </Grid>
          <Grid item sx={{ mt: "10px" }}>
            <LoadingButton disabled={!settingsChanged || isLoading} loading={isLoading} type="submit">
              {t(Translations.PAGE_PROJECT_STORE_SETTINGS_BUTTON_UPDATE)}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default StoreSettingsContent;
