import { Card, CardActionArea, CardContent, CardMedia, Grid, IconButton, Typography, styled } from "@mui/material";
import { type DB_StoreProductDocument } from "dataLayer/types/storeProductEndpoints";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import { useCallback } from "react";
import { dispatch } from "store/hooks";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import useProductListActions, { ProductAction } from "hooks/actionHooks/useProductListActions";

interface ProductCardProps {
  data: DB_StoreProductDocument;
  withButtons?: boolean;
}

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "transform 0.1s ease-in-out",
  "&:hover": { transform: "scale3d(1.04, 1.04, 1.04)" },
}));

const ProductCard = ({ data, withButtons }: ProductCardProps) => {
  const { storeId } = useProjectStore();

  const onEditClick = useCallback(() => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.StoreProductModal,
        props: {
          storeId,
          productId: data.id,
        },
      }),
    );
  }, [storeId, data]);

  const actionBindingsGenerator = useProductListActions(storeId!);
  const actionBindings = actionBindingsGenerator(data);
  const onCloneClick = actionBindings.find((action) => action.id === ProductAction.CLONE)?.actionCallback;
  const onRemoveClick = actionBindings.find((action) => action.id === ProductAction.REMOVE)?.actionCallback;

  return (
    <StyledCard>
      <Grid container item xs={12}>
        <CardActionArea onClick={onEditClick}>
          <CardContent>
            <Typography variant="body2" noWrap>{`${data.title}`}</Typography>
          </CardContent>
          <CardMedia component="img" image={data.image!} alt={data.title} />
          <Grid container justifyContent={"flex-end"}>
            <Typography
              variant="h6"
              sx={{
                mt: -3,
                mr: 1,
              }}
            >{`$${data.price}`}</Typography>
          </Grid>
        </CardActionArea>
        {withButtons ? (
          <CardContent>
            <Grid container item xs={12}>
              <Grid item xs={3}>
                <IconButton>
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={onEditClick}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={onCloneClick}>
                  <FileCopyIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={3}>
                <IconButton onClick={onRemoveClick}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
        ) : null}
      </Grid>
    </StyledCard>
  );
};

export default ProductCard;
