import { type IStoreMeta } from "store/types/dashboard";
import DrawerListGroup from "../common/DrawerListGroup";
import DrawerListItem from "../common/DrawerListItem";
import { ImageIcon } from "components/common/ImageIcon";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import useProject from "pages/Dashboard/hooks/useProject";
import { type IClientStoreType } from "dataLayer/types/storeTypeEndpoints";

interface DrawerStoreTypeProps {
  stores: IStoreMeta[];
  storeType: IClientStoreType;
}

const DrawerStoreType = ({ storeType, stores }: DrawerStoreTypeProps) => {
  const navigate = useNavigate();
  const { PROJECT } = useProject();

  const onStoreClick = useCallback(
    (storeId: string) => {
      navigate(`/dashboard/${PROJECT!.id}/${storeType.key}/${storeId}`);
    },
    [storeType, navigate, PROJECT],
  );

  return (
    <DrawerListGroup title={storeType.title}>
      {stores.map((storeData) => (
        <DrawerListItem
          key={storeData.id}
          title={storeData.title}
          icon={<ImageIcon iconURL={storeData.icon} />}
          onClick={() => {
            onStoreClick(storeData.id);
          }}
        />
      ))}
    </DrawerListGroup>
  );
};

export default DrawerStoreType;
