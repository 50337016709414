import { type PropsWithChildren, useState } from "react";
import StoreCategoryContext, { type IStoreCategoryContextFields } from "./StoreCategoryContext";
import { type CategoryVisibilityConfig } from "dataLayer/types/storeCategoryEndpoints";

interface StoreCategoryContextProviderProps extends PropsWithChildren {
  storeId: string;
  categoryId: string | null;
  defaultValues: IStoreCategoryContextFields | null;
}

const StoreCategoryContextProvider = ({
  storeId,
  categoryId,
  defaultValues,
  children,
}: StoreCategoryContextProviderProps) => {
  const [title, setTitle] = useState<string>(defaultValues?.title ?? "");
  const [items, setItems] = useState<string[]>(defaultValues?.items ?? []);
  const [isEnabled, setIsEnabled] = useState<boolean>(defaultValues?.isEnabled ?? false);
  const [isVisible, setIsVisible] = useState<boolean | null>(defaultValues?.isVisible ?? true);
  const [visibilityConfig, setVisibilityConfig] = useState<CategoryVisibilityConfig | null>(
    defaultValues?.visibilityConfig ?? null,
  );

  return (
    <StoreCategoryContext.Provider
      value={{
        storeId,
        categoryId,
        title,
        setTitle,
        items,
        setItems,
        isEnabled,
        setIsEnabled,
        isVisible,
        setIsVisible,
        visibilityConfig,
        setVisibilityConfig,
      }}
    >
      {children}
    </StoreCategoryContext.Provider>
  );
};

export default StoreCategoryContextProvider;
