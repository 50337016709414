import { type ChangeEvent, type KeyboardEvent, type ClipboardEvent, useContext, useEffect, useState } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useTranslation } from "react-i18next";

const ProductPriceField = () => {
  const [t] = useTranslation();
  const { price, setPrice } = useContext(StoreProductContext);
  const [value, setValue] = useState(price);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    const updatedValue = event.target.value;
    if (/^\d*(\.\d{0,2})?$/.test(updatedValue)) {
      setValue(event.target.value);
    } else {
      event.preventDefault();
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (["e", "E", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: ClipboardEvent) => {
    const pasteData = event.clipboardData?.getData("text");
    if (!pasteData || !/^\d*(\.\d{0,2})?$/.test(pasteData)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setPrice(value);
  }, [value, setPrice]);

  return (
    <TextField
      autoFocus
      required
      margin="dense"
      key={Translations.MODAL_CREATE_PRODUCT_FIELD_PRICE}
      name={Translations.MODAL_CREATE_PRODUCT_FIELD_PRICE}
      label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_PRICE)}
      type="number"
      fullWidth
      variant="outlined"
      value={value}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      InputProps={{
        startAdornment: <AttachMoneyIcon />,
      }}
      sx={{
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
          display: "none",
        },
        "& input[type=number]": {
          MozAppearance: "textfield",
        },
      }}
      onChange={(e) => {
        setPrice(e.target.value);
      }}
    />
  );
};

export default ProductPriceField;
