import { useCallback, useContext, useMemo, useState } from "react";
import StoreModalContext from "../../context/StoreModalContext";
import { Checkbox, DialogActions, FormControlLabel, Grid, Link, TextField, Typography } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";

import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import useStoreTypes from "pages/Dashboard/hooks/useStoreTypes";
import SubDomainField from "./fields/SubDomainField";
import useStoreModalHooks from "./hooks/useStoreModalHooks";

const FormFillStep = () => {
  const { storeId, storeTitle, setStoreTitle, step, setStep, storeType, subdomain, icon, setIcon } =
    useContext(StoreModalContext);

  const { getStoreTypeById } = useStoreTypes();
  const { onSubmit } = useStoreModalHooks();

  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const subdomainCheckResult = useAppSelector(storeSelector.subdomainCheckResult);
  const isStoreCreateLoading = useAppSelector(storeSelector.isStoreCreateLoading);

  const onBackClick = useCallback(() => {
    setStep(step - 1);
  }, [step, setStep]);

  const isSubmitEnabled = useMemo(
    () => !!storeTitle && !!subdomain && subdomainCheckResult && termsAgreed,
    [termsAgreed, subdomain, storeTitle, subdomainCheckResult],
  );

  const storeTypeDetails = getStoreTypeById(storeType);

  return (
    <>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant="h5">
            {t(Translations.MODAL_CREATE_STORE_FORM_TITLE, {
              storeType: storeTypeDetails?.title.toUpperCase(),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{t(Translations.MODAL_CREATE_STORE_FORM_SUBTITLE)}</Typography>
        </Grid>
        <TextField
          autoFocus
          required
          margin="dense"
          key={Translations.MODAL_CREATE_STORE_FORM_FIELD_TITLE}
          name="storeTitle"
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_TITLE)}
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={storeTitle}
          onChange={(_) => {
            setStoreTitle(_.target.value);
          }}
        />
        <SubDomainField />
        <TextField
          margin="dense"
          key={Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON}
          name="icon"
          label={t(Translations.MODAL_CREATE_STORE_FORM_FIELD_ICON)}
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={icon}
          onChange={(_) => {
            setIcon(_.target.value);
          }}
        />
        <FormControlLabel
          required
          control={
            <Checkbox
              name={Translations.MODAL_SIGN_UP_CHECKBOX_TERMS_CONDITIONS}
              checked={termsAgreed}
              onChange={(_, checked) => {
                setTermsAgreed(checked);
              }}
            />
          }
          label={
            <>
              {t(Translations.MODAL_SIGN_UP_DOCS_READ_PREFIX)}
              <Link href="/documentation/terms-and-conditions" target="_blank">
                {t(Translations.MODAL_SIGN_UP_CHECKBOX_TERMS_CONDITIONS)}
              </Link>
            </>
          }
        />
      </Grid>
      <DialogActions>
        <LoadingButton loading={isStoreCreateLoading} onClick={onBackClick}>
          {t(Translations.MODAL_CREATE_STORE_FORM_BUTTON_BACK)}
        </LoadingButton>
        <LoadingButton loading={isStoreCreateLoading} onClick={onSubmit} disabled={!isSubmitEnabled}>
          {t(
            storeId
              ? Translations.MODAL_EDIT_STORE_FORM_BUTTON_UPDATE
              : Translations.MODAL_CREATE_STORE_FORM_BUTTON_NEXT,
          )}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default FormFillStep;
