import { type PropsWithChildren, useMemo, useState } from "react";
import StoreSettingsContext from "./StoreSettingsContext";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

interface StoreSettingsContextProviderProps extends PropsWithChildren {}

const StoreSettingsContextProvider = ({ children }: StoreSettingsContextProviderProps) => {
  const storeDetails = useAppSelector(storeSelector.store)!;
  const storeTypes = useAppSelector(storeSelector.storeTypes);
  const storeType = useMemo(() => {
    return storeTypes?.find((type) => type.id === storeDetails?.type)?.key ?? null;
  }, [storeTypes, storeDetails]);
  const [url, setUrl] = useState<string | null>(storeDetails.settings.customDomain);
  const [icon, setIcon] = useState<string | null>(storeDetails.icon);
  const [subdomain, setSubdomain] = useState<string | null>(storeDetails.settings.subdomain);
  const [storeTitle, setStoreTitle] = useState<string | null>(storeDetails.title);

  return (
    <StoreSettingsContext.Provider
      value={{
        storeType,
        url,
        icon,
        subdomain,
        storeTitle,
        setStoreTitle,
        setUrl,
        setSubdomain,
        setIcon,
      }}
    >
      {children}
    </StoreSettingsContext.Provider>
  );
};

export default StoreSettingsContextProvider;
