import { Grid } from "@mui/material";
import DataTable, { type ColumnData } from "components/common/DataTable";
import { Translations } from "constants/translations";
import { useTranslation } from "react-i18next";
import { dispatch, useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { type IProductsTableRowData } from "./types";
import { type DB_StoreProductDocument } from "dataLayer/types/storeProductEndpoints";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import useProductListActions from "hooks/actionHooks/useProductListActions";

const columns: Array<ColumnData<IProductsTableRowData>> = [
  {
    dataKey: "title",
    label: Translations.PAGE_PROJECT_STORE_PRODUCTS_LIST_COLUMN_TITLE,
    width: "90%",
  },
  {
    dataKey: "price",
    label: Translations.PAGE_PROJECT_STORE_PRODUCTS_LIST_COLUMN_PRICE,
  },
];

interface EmptyStateProps {
  categorized?: boolean;
}

const EmptyState = ({ categorized: isCategorized }: EmptyStateProps) => {
  const [t] = useTranslation();
  return (
    <Grid container justifyContent={"center"} sx={{ py: 4 }}>
      {t(
        isCategorized
          ? Translations.PAGE_PROJECT_STORE_PRODUCTS_LIST_EMPTY_CATEGORY
          : Translations.PAGE_PROJECT_STORE_PRODUCTS_LIST_EMPTY,
      )}
    </Grid>
  );
};

const convertData = (products: DB_StoreProductDocument[] = []): IProductsTableRowData[] =>
  products.map((product) => ({
    id: product.id,
    title: product.title,
    price: `$${product.price}`,
  }));

interface ProductsListViewProps extends EmptyStateProps {
  productsList?: DB_StoreProductDocument[];
  enableActions?: boolean;
}

const ProductsListView = ({ productsList, enableActions, categorized: isCategorized }: ProductsListViewProps) => {
  const { storeId } = useProjectStore();

  const allProducts = useAppSelector(storeSelector.products);

  const products = productsList ?? allProducts;

  const convertedProducts = convertData(products ?? []);

  const onRowClick = (rowData: IProductsTableRowData) => {
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.StoreProductModal,
        props: {
          storeId,
          productId: rowData.id,
        },
      }),
    );
  };

  const actionBindingsGenerator = useProductListActions(storeId!);

  return (
    <DataTable
      data={convertedProducts}
      columns={columns}
      emptyState={<EmptyState categorized={isCategorized} />}
      onRowClick={onRowClick}
      actions={
        enableActions
          ? {
              actionBindingsGenerator,
            }
          : undefined
      }
    />
  );
};

export default ProductsListView;
