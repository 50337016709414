import { contextSetterImitator } from "constants/context";
import { type Dispatch, type SetStateAction, createContext } from "react";

export interface IStoreModalContext {
  storeId: string | null;
  step: number;
  storeType: string | null;
  storeTitle: string | null;
  url: string | null;
  icon: string | null;
  subdomain: string | null;
  setStep: Dispatch<SetStateAction<number>>;
  setStoreTitle: Dispatch<SetStateAction<string | null>>;
  setStoreType: Dispatch<SetStateAction<string | null>>;
  setUrl: Dispatch<SetStateAction<string | null>>;
  setIcon: Dispatch<SetStateAction<string | null>>;
  setSubdomain: Dispatch<SetStateAction<string | null>>;
}

const StoreModalContext = createContext<IStoreModalContext>({
  storeId: null,
  step: 0,
  storeType: null,
  storeTitle: null,
  url: null,
  icon: null,
  subdomain: null,
  setStep: contextSetterImitator,
  setStoreTitle: contextSetterImitator,
  setStoreType: contextSetterImitator,
  setUrl: contextSetterImitator,
  setIcon: contextSetterImitator,
  setSubdomain: contextSetterImitator,
});

export default StoreModalContext;
