import { format } from "date-fns";

const dateOnlyValueFormat = "yyyy-MM-dd";
export const dateOnlyDisplayFormat = "MMM dd, yyyy";

// const dateOnlyWithoutYear = "MMM dd";
// const timeOnlyValueFormat = "HH:mm:ss";

export const convertToDateOnly = (date: Date | null) => {
  if (!date) {
    return null;
  }
  return format(date, dateOnlyValueFormat);
};

export const convertFromDateOnly = (dateOnly: string | null) => {
  if (!dateOnly) {
    return dateOnly;
  }
  return new Date(dateOnly);
};

export const formatDateOnly = (dateOnly: string | null) => {
  if (!dateOnly) {
    return null;
  }
  return format(convertFromDateOnly(dateOnly)!, dateOnlyDisplayFormat);
};

export const convertFromDateTime = (dateTime: string | null) => {
  if (!dateTime) {
    return dateTime;
  }
  return new Date(dateTime);
};

export const convertToIso = (date: Date) => {
  return date.toISOString();
};
