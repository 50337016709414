import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "store/store";

const selector = (state: RootState) => state.store;

const store = createSelector([selector], (state) => state.store);
const categories = createSelector([selector], (state) => state.store?.entities.categories);
const products = createSelector([selector], (state) => state.store?.entities.products);
const settings = createSelector([selector], (state) => state.store?.settings);
const loadingStates = createSelector([selector], (state) => state.loadingStates);
const storeTypes = createSelector([selector], (state) => state.storeTypes.list);
const storeTypesLoading = createSelector([selector], (state) => state.storeTypes.isLoading);
const isStoreLoading = createSelector([selector], (state) => state.loadingStates.isStoreLoading);
const isCategoryLoading = createSelector([selector], (state) => state.loadingStates.isCategoryLoading);
const isProductLoading = createSelector([selector], (state) => state.loadingStates.isProductLoading);
const isStoreCreateLoading = createSelector([selector], (state) => state.loadingStates.isStoreCreateLoading);
const isStoreRemoveLoading = createSelector([selector], (state) => state.loadingStates.isStoreRemoveLoading);
const isStoreUpdateLoading = createSelector([selector], (state) => state.loadingStates.isStoreUpdateLoading);
const projectStoreInLoading = createSelector([selector], (state) => state.loadingStates.projectStoreInLoading);
const subdomainCheckResult = createSelector([selector], (state) => state.subdomainCheckResult.result);
const subdomainCheckResultLoading = createSelector([selector], (state) => state.subdomainCheckResult.isLoading);

export default {
  store,
  categories,
  products,
  settings,
  storeTypes,
  storeTypesLoading,
  loadingStates,
  isStoreLoading,
  isCategoryLoading,
  isProductLoading,
  isStoreCreateLoading,
  isStoreRemoveLoading,
  isStoreUpdateLoading,
  projectStoreInLoading,
  subdomainCheckResult,
  subdomainCheckResultLoading,
};
