import { type BreadCrumbItemProps } from "components/Dashboard/Project/ProjectBreadcrumb/ProjectBreadcrumbItem";
import { Translations } from "constants/translations";
import useProject from "pages/Dashboard/hooks/useProject";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import useStoreTypes from "pages/Dashboard/hooks/useStoreTypes";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

const useDashboardBreadcrumbItems = () => {
  const { PROJECT } = useProject();
  const { STORE } = useProjectStore();
  const { getStoreTypeById } = useStoreTypes();
  const { pathname } = useLocation();
  const { projectId, storeTypeId, storeId } = useParams();

  const dynamicPathComposition = useMemo(() => {
    let path = "/dashboard";
    if (projectId) {
      path += `/${projectId}`;
    }
    if (storeTypeId) {
      path += `/${storeTypeId}`;
    }
    if (storeId) {
      path += `/${storeId}`;
    }
    return path;
  }, [projectId, storeTypeId, storeId]);

  const storeSubPage = pathname.substring(dynamicPathComposition.length + 1);

  const breadCrumbItems = useMemo(() => {
    const items: BreadCrumbItemProps[] = [
      {
        key: Translations.BREADCRUMB_DASHBOARD,
        title: Translations.BREADCRUMB_DASHBOARD,
        href: "/dashboard",
      },
    ];
    if (PROJECT) {
      items.push(
        {
          key: Translations.BREADCRUMB_PROJECTS,
          title: Translations.BREADCRUMB_PROJECTS,
        },
        {
          key: PROJECT.id,
          title: PROJECT.title,
          href: `/dashboard/${PROJECT.id}`,
        },
      );
      if (STORE) {
        items.push(
          {
            key: STORE.storeType,
            title: getStoreTypeById(STORE.storeType)?.title ?? "",
          },
          {
            key: STORE.id,
            title: `${STORE.title}`,
            href: `/dashboard/${PROJECT.id}/${STORE.storeType}/${STORE.id}`,
          },
        );
        if (storeSubPage) {
          items.push({
            key: storeSubPage,
            title: `${Translations.BREADCRUMB_STORE}.${storeSubPage}`,
          });
        }
      }
    }
    return items;
  }, [PROJECT, STORE, storeSubPage]);

  return breadCrumbItems;
};

export default useDashboardBreadcrumbItems;
