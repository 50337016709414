import { type IProductsTableRowData } from "components/Dashboard/ProjectStore/ProductsOverview/components/types";
import { type ConfirmationModalProps } from "components/modals/shared/ConfirmationModal";
import { Translations } from "constants/translations";
import { type IContextActionBinding } from "hooks/useContextMenuAction";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dispatch } from "store/hooks";
import { storeMiddleware } from "store/slices/store";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

export enum ProductAction {
  REMOVE = "remove",
  CLONE = "clone",
}

const useProductListActions = (storeId: string) => {
  const [t] = useTranslation();

  const onRemove = useCallback(
    (productId: string) => {
      const props: ConfirmationModalProps = {
        title: t(Translations.MODAL_CONFIRMATION_TITLE_REMOVE_PRODUCT),
        subtitle: t(Translations.MODAL_CONFIRMATION_SUBTITLE_REMOVE_PRODUCT),
        confirmationCallback: () => {
          dispatch(
            storeMiddleware.removeStoreProduct({
              storeId,
              productId,
            }),
          );
        },
      };
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.ConfirmationModal,
          props,
        }),
      );
    },
    [storeId, t],
  );

  const onClone = useCallback(
    (productId: string) => {
      dispatch(
        viewsMiddleware.openModal({
          name: ModalName.CloneStoreProductModal,
          props: {
            storeId,
            productId,
          },
        }),
      );
    },
    [storeId],
  );

  return (rowData: IProductsTableRowData): IContextActionBinding[] => {
    return [
      {
        id: ProductAction.REMOVE,
        title: t(Translations.CONTEXT_ACTION_PRODUCT_REMOVE),
        actionCallback: () => {
          onRemove(rowData.id);
        },
      },
      {
        id: ProductAction.CLONE,
        title: t(Translations.CONTEXT_ACTION_PRODUCT_CLONE),
        actionCallback: () => {
          onClone(rowData.id);
        },
      },
    ];
  };
};

export default useProductListActions;
