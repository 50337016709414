import { DialogContent, Grid, Portal } from "@mui/material";
import ProductTitleField from "./components/fields/ProductTitleField";
import ProductImageField from "./components/fields/ProductImageField";
import ProductDescriptionField from "./components/fields/ProductDescriptionField";
import ProductImagePreview from "./components/fields/ProductImagePreview";
import { useRef } from "react";
import ProductCategoriesField from "./components/fields/ProductCategoriesField";
import ProductSubitemsField from "./components/fields/ProductSubitemsField";
import StoreProductTabs from "./components/tabs/ProductPricingSection";

const FormBody = () => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const portalRef = useRef(null);

  return (
    <>
      <DialogContent>
        <Grid container spacing={2} alignContent={"start"}>
          <Grid item xs={8}>
            <ProductTitleField />
          </Grid>
          <Grid item xs={4}>
            <ProductImageField />
          </Grid>
          <Grid item xs={8}>
            <div ref={portalRef} />
          </Grid>
          <Grid item xs={4}>
            <ProductImagePreview ref={imageRef} />
          </Grid>
          <Grid item xs={12}>
            <ProductCategoriesField />
          </Grid>
          <Grid item xs={12}>
            <ProductSubitemsField />
          </Grid>
          <Grid item xs={12}>
            <StoreProductTabs />
          </Grid>
        </Grid>
      </DialogContent>
      <Portal container={() => portalRef.current}>
        <ProductDescriptionField imageRef={imageRef} />
      </Portal>
    </>
  );
};

export default FormBody;
