import { Box, Paper } from "@mui/material";
import { type PropsWithChildren } from "react";
import StoreSettingsContextProvider from "../context/StoreSettingsContextProvider";
import { useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";

interface StoreSettingsWrapperProps extends PropsWithChildren {}

const StoreSettingsWrapper = ({ children }: StoreSettingsWrapperProps) => {
  const currentStore = useAppSelector(storeSelector.store);
  return currentStore ? (
    <Box>
      <StoreSettingsContextProvider>
        <Paper>{children}</Paper>
      </StoreSettingsContextProvider>
    </Box>
  ) : null;
};

export default StoreSettingsWrapper;
