import {
  type IStoreTypesState,
  type IStoreLoadingStates,
  type IStoreSliceState,
  type ISubdomainCheckState,
} from "store/types/store";

export const getStoreInitialLoadingStates = (): IStoreLoadingStates => ({
  isStoreLoading: false,
  isStoreCreateLoading: false,
  isStoreUpdateLoading: false,
  isStoreRemoveLoading: false,
  isCategoryLoading: false,
  isProductLoading: false,
  projectStoreInLoading: null,
});

export const getStoreTypesInitialState = (): IStoreTypesState => ({
  list: null,
  isLoading: false,
});

export const getSubdomainCheckInitialState = (): ISubdomainCheckState => ({
  isLoading: false,
  result: null,
});

export const getInitialState = (): IStoreSliceState => ({
  store: null,
  loadingStates: getStoreInitialLoadingStates(),
  storeTypes: getStoreTypesInitialState(),
  subdomainCheckResult: getSubdomainCheckInitialState(),
});
