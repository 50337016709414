import { contextSetterImitator } from "constants/context";
import { type Dispatch, type SetStateAction, createContext } from "react";

export interface IStoreModalContext {
  storeType: string | null;
  storeTitle: string | null;
  url: string | null;
  icon: string | null;
  subdomain: string | null;
  setStoreTitle: Dispatch<SetStateAction<string | null>>;
  setUrl: Dispatch<SetStateAction<string | null>>;
  setIcon: Dispatch<SetStateAction<string | null>>;
  setSubdomain: Dispatch<SetStateAction<string | null>>;
}

const StoreSettingsContext = createContext<IStoreModalContext>({
  storeType: null,
  storeTitle: null,
  url: null,
  icon: null,
  subdomain: null,
  setStoreTitle: contextSetterImitator,
  setUrl: contextSetterImitator,
  setIcon: contextSetterImitator,
  setSubdomain: contextSetterImitator,
});

export default StoreSettingsContext;
