import { type PropsWithChildren, useState } from "react";
import StoreModalContext from "./StoreModalContext";
import useProjectStore from "pages/Dashboard/hooks/useProjectStore";

interface StoreModalContextProviderProps extends PropsWithChildren {
  storeId: string | null;
}

const StoreModalContextProvider = ({ storeId, children }: StoreModalContextProviderProps) => {
  const { getStoreById } = useProjectStore();
  const storeDetails = storeId ? getStoreById(storeId) : null;
  const [step, setStep] = useState<number>(storeDetails ? 1 : 0);
  const [url, setUrl] = useState<string | null>(storeDetails ? storeDetails.customDomain : null);
  const [icon, setIcon] = useState<string | null>(storeDetails ? storeDetails.icon : null);
  const [subdomain, setSubdomain] = useState<string | null>(storeDetails ? storeDetails.subdomain : null);
  const [storeType, setStoreType] = useState<string | null>(storeDetails ? storeDetails.storeType : null);
  const [storeTitle, setStoreTitle] = useState<string | null>(storeDetails ? storeDetails.title : null);

  return (
    <StoreModalContext.Provider
      value={{
        storeId,
        step,
        setStep,
        storeType,
        url,
        icon,
        subdomain,
        setStoreType,
        storeTitle,
        setStoreTitle,
        setUrl,
        setSubdomain,
        setIcon,
      }}
    >
      {children}
    </StoreModalContext.Provider>
  );
};

export default StoreModalContextProvider;
