import { useCallback, useContext, useMemo } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { Translations } from "constants/translations";
import { dispatch, useAppSelector } from "store/hooks";
import { storeSelector } from "store/slices/store";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { convertProductsIntoOptions } from "utils/convertingUtils";
import { useTranslation } from "react-i18next";
import { type SelectProductModalProps } from "components/modals/Store/SelectProductModal";
import { viewsMiddleware } from "store/slices/views";
import { ModalName } from "store/types/views";

const ProductSubitemsField = () => {
  const [t] = useTranslation();
  const { subItems, setSubItems, productId, commands } = useContext(StoreProductContext);
  const items = useAppSelector(storeSelector.products);

  const isEnabled = !commands.length;

  const itemsArray = useMemo(() => items ?? [], [items]);

  const options = useMemo(() => convertProductsIntoOptions(itemsArray, productId), [itemsArray, productId]);

  const selectedOptions = useMemo(() => options.filter((option) => subItems.includes(option.id)), [options, subItems]);

  const openProductSelectionModal = useCallback(() => {
    const props: SelectProductModalProps = {
      multiple: true,
      preselectedProductIds: subItems,
      selectionCallback: setSubItems,
    };
    dispatch(
      viewsMiddleware.openModal({
        name: ModalName.SelectProductModal,
        props,
      }),
    );
  }, [subItems, setSubItems]);

  return isEnabled ? (
    <Autocomplete
      multiple
      open={false}
      options={options}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_SUBITEMS)}
          variant="outlined"
          onClick={openProductSelectionModal}
        />
      )}
      value={selectedOptions}
      onChange={(_, values) => {
        setSubItems(values.map((value) => value.id));
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.title}
        </li>
      )}
    />
  ) : (
    <Grid container item xs={12}>
      <TextField fullWidth disabled value={t(Translations.MODAL_CREATE_PRODUCT_FIELD_SUBITEMS_DISABLED)} />
    </Grid>
  );
};

export default ProductSubitemsField;
