import React, { useEffect } from "react";
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import "./utils/i18n";
import HomeLayout from "layouts/HomeLayout";
import RootLayout from "layouts/RootLayout";
import DashboardPage from "pages/Dashboard";
import HomePage from "pages/Home";
import AboutPage from "pages/Home/About";
import PrivacyPolicyPage from "pages/Documentation/PrivacyPolicy";
import TermsAndConditionsPage from "pages/Documentation/TermsAndConditions";
import NotFoundPage from "pages/NotFound";
import { dispatch, useAppSelector } from "store/hooks";
import { coreMiddleware, coreSelector } from "store/slices/core";
import DashboardLayout from "layouts/DashboardLayout";
import GlobalLoading from "components/GlobalLoading";
import ProjectPage from "pages/Dashboard/project";
import StorePage from "pages/Dashboard/store";
import CategoriesPage from "pages/Dashboard/store/Categories";
import ProjectLayout from "layouts/ProjectLayout";
import StoreLayout from "layouts/StoreLayout";
import ProductsPage from "pages/Dashboard/store/Products";
import StoreSettingsPage from "pages/Dashboard/store/Settings";

const App = () => {
  const firebase = useAppSelector(coreSelector.firebase);
  const authentication = useAppSelector(coreSelector.authentication);
  const { isInitialized } = firebase;
  const { initialCheckComplete } = authentication;

  const initializationComplete = isInitialized && initialCheckComplete;

  useEffect(() => {
    if (!firebase.isInitialized && !firebase.isInitializationInProgress) {
      dispatch(coreMiddleware.initialize());
    }
  }, [firebase]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Navigate to={authentication.user ? "/dashboard" : "/home"} replace />} />
        <Route path="home" element={<HomeLayout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
        </Route>
        <Route path="documentation" element={<HomeLayout />}>
          <Route path="terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        </Route>
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path=":projectId" element={<ProjectLayout />}>
            <Route index element={<ProjectPage />} />
            <Route path=":storeTypeId">
              <Route path=":storeId" element={<StoreLayout />}>
                <Route index element={<StorePage />} />
                <Route path="categories" element={<CategoriesPage />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="settings" element={<StoreSettingsPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>,
    ),
  );

  return <>{initializationComplete ? <RouterProvider router={router} /> : <GlobalLoading show />}</>;
};

export default App;
