import { useContext } from "react";
import StoreProductContext from "../../context/StoreProductContext";
import { TextField } from "@mui/material";
import { Translations } from "constants/translations";
import { t } from "i18next";

const ProductTitleField = () => {
  const { title, setTitle } = useContext(StoreProductContext);
  return (
    <TextField
      autoFocus
      required
      margin="dense"
      key={Translations.MODAL_CREATE_PRODUCT_FIELD_NAME}
      name={Translations.MODAL_CREATE_PRODUCT_FIELD_IMAGE}
      label={t(Translations.MODAL_CREATE_PRODUCT_FIELD_NAME)}
      type="text"
      fullWidth
      variant="outlined"
      value={title ?? ""}
      onChange={(_) => {
        setTitle(_.target.value);
      }}
    />
  );
};

export default ProductTitleField;
