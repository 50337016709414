import { Card, CardActionArea, CardMedia, styled } from "@mui/material";
import { type StoreTypeCardProps } from "./StoreTypeCard";

interface StyledCardProps {}

const StyledCard = styled(Card)((props: StyledCardProps) => ({
  opacity: 0.3,
}));

const StoreTypeSoonCard = ({ config }: StoreTypeCardProps) => {
  return (
    <StyledCard>
      <CardActionArea disabled>
        <CardMedia component="img" image={config.backgroundImageURL ?? ""} alt={config.title} />
      </CardActionArea>
    </StyledCard>
  );
};

export default StoreTypeSoonCard;
