import { Grid, useTheme } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorWrapper from "./EditorWrapper";
import { type CSSProperties } from "react";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      {
        color: ["white", "grey", "red", "blue", "yellow", "green", "orange"],
      },
    ],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

interface HtmlEditorProps {
  id: string;
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  editorStyle?: CSSProperties;
}

const HtmlEditor = ({ id, value, onChange, placeholder, editorStyle }: HtmlEditorProps) => {
  const theme = useTheme();

  return (
    <Grid container className="editor" height={200}>
      <EditorWrapper style={editorStyle} id={id}>
        <ReactQuill
          style={{
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.white,
          }}
          value={value}
          onChange={onChange}
          modules={modules}
          placeholder={placeholder}
        />
      </EditorWrapper>
    </Grid>
  );
};

export default HtmlEditor;
