import useProjectStore from "pages/Dashboard/hooks/useProjectStore";
import NavigationListWrapper from "./NavigationListWrapper";
import useProject from "pages/Dashboard/hooks/useProject";
import { Translations } from "constants/translations";
import { Divider } from "@mui/material";
import DrawerListItem from "../common/DrawerListItem";
import CategoryIcon from "@mui/icons-material/Category";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import LayersIcon from "@mui/icons-material/Layers";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SettingsIcon from "@mui/icons-material/Settings";
import DnsIcon from "@mui/icons-material/Dns";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";

const StoreNavigation = () => {
  const [t] = useTranslation();
  const { PROJECT } = useProject();
  const { STORE } = useProjectStore();

  return STORE ? (
    <>
      <NavigationListWrapper
        previousNavigationDetails={{
          title: Translations.LAYOUT_DASHBOARD_PROJECT_NAVIGATION_BACK_TITLE,
          path: `/dashboard/${PROJECT?.id}`,
        }}
      >
        <Divider />
        <DrawerListItem
          title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_CATEGORIES)}
          icon={<CategoryIcon />}
          path={`/dashboard/${PROJECT?.id}/${STORE.storeType}/${STORE?.id}/categories`}
        />
        <DrawerListItem
          title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_PRODUCTS)}
          icon={<ProductionQuantityLimitsIcon />}
          path={`/dashboard/${PROJECT?.id}/${STORE.storeType}/${STORE?.id}/products`}
        />
        <DrawerListItem
          title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_STATISTICS)}
          icon={<StackedLineChartIcon />}
        />
        <DrawerListItem title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_PAGES)} icon={<LayersIcon />} />
        <DrawerListItem title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_SERVER)} icon={<DnsIcon />} />
        <DrawerListItem title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_CLIENTS)} icon={<PeopleIcon />} />
        <DrawerListItem
          title={t(Translations.LAYOUT_DASHBOARD_STORE_NAVIGATION_SETTINGS)}
          icon={<SettingsIcon />}
          path={`/dashboard/${PROJECT?.id}/${STORE.storeType}/${STORE?.id}/settings`}
        />
      </NavigationListWrapper>
    </>
  ) : null;
};

export default StoreNavigation;
